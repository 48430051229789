import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { createHack } from '../../api/CurrentHacks';

const CreateIdeaModal = props => {
  const { register, handleSubmit, errors } = useForm();
  const [statusText, setStatusText] = useState(
    'Have an idea but dont have time to work on it? Use this.'
  );
  const [displayLookingFor, setDisplayLookingFor] = useState(true);

  async function submitHackSheetRequest() {
    const rowgoogleSheetRowId = '=ROW()';
    const titleValue = document.getElementById('idea-name-input').value;
    const teamValues = document.getElementById('hack-teammembers-input').value;
    const descriptionValue = document.getElementById('idea-description-input').value;
    const typeValues = document.getElementById('hack-idea-type-input').value;
    const pocValues = document.getElementById('hack-POC-input').value;
    const productTypeValue = document.getElementById('hack-idea-product-input').value;
    const hackStatusValues = document.getElementById('hack-idea-status-input').value;
    const submissionTypeValue = document.getElementById('hack-idea-submission-input').value;
    const lookingForValue = document?.getElementById('hack-idea-looking-for-input')?.value || '';

    var values = [
      [
        rowgoogleSheetRowId,
        titleValue,
        teamValues,
        descriptionValue,
        typeValues,
        pocValues,
        productTypeValue,
        hackStatusValues,
        submissionTypeValue,
        lookingForValue,
      ],
    ];

    await createHack({ values });
    window.location.reload();
  }

  const handleClose = () => props.setCreateIdeaModal(false);

  function handleStatusSelectChange(event) {
    let selectElement = event.target;
    let value = selectElement.value;
    if (value === 'Open Idea') {
      setStatusText('Have an idea but dont have time to work on it? Use this.');
      setDisplayLookingFor(true);
    } else if (value === 'Closed Team') {
      setStatusText('Full Team? This will remove the Join Hack button from the card.');
      setDisplayLookingFor(false);
    } else if (value === 'Ready to Present') {
      setStatusText('Congratulations! Someone may reach out to confirm details.');
      setDisplayLookingFor(false);
    } else if (value === 'Looking for Teammates') {
      setStatusText('Looking for people to help you on your hack? Use this!');
      setDisplayLookingFor(true);
    }
  }

  return (
    <>
      <Modal show={props.isCreateIdeaModalVisible} onHide={handleClose} dialogClassName="">
        <Modal.Header>
          <Modal.Title>Cool. What Hack? And Who?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className="modal-form"
            id="idea-modal-form"
            method="POST"
            onSubmit={handleSubmit(() => submitHackSheetRequest())}
          >
            <label htmlFor="hack-idea-name">Hack Name</label>
            <input
              className="idea-modal-input-box"
              type="text"
              name="hackIdeaName"
              id="idea-name-input"
              placeholder="Give it a snappy name"
              ref={register({
                required: 'Hack Name is required.',
              })}
            />
            {errors.hackIdeaName && <div className="errorMsg">{errors.hackIdeaName.message}</div>}
            {/* ----------------------------------- */}
            <label htmlFor="idea-description-input">Hack Description</label>
            <textarea
              className="idea-modal-input-box"
              id="idea-description-input"
              name="hackIdeaDescription"
              rows="5"
              placeholder="A short paragraph's worth. Don't forget to include if you're looking for a particular type of team member (Front End Engineer, Data Scientist, etc)"
              ref={register({
                required: 'Hack Description is required.',
              })}
            />
            {errors.hackIdeaDescription && (
              <div className="errorMsg">{errors.hackIdeaDescription.message}</div>
            )}
            {/* ----------------------------------- */}
            <label htmlFor="hack-teammembers-input">The Team</label>
            <input
              className="idea-modal-input-box"
              type="text"
              name="hackIdeaTeammembers"
              id="hack-teammembers-input"
              placeholder="Your Name, Jane Doe, Joe Smith...separate by commas"
              ref={register({
                required: 'Team is required, it should be at least one person (yourself).',
              })}
            />
            {errors.hackIdeaTeammembers && (
              <div className="errorMsg">{errors.hackIdeaTeammembers.message}</div>
            )}
            {/* ----------------------------------- */}
            <label htmlFor="hack-POC-input">Point of Contact</label>
            <input
              className="idea-modal-input-box"
              type="email"
              name="hackIdeaEmail"
              id="hack-POC-input"
              placeholder="you@numerator.com"
              ref={register({
                required: 'Your email is required.',
                pattern: {
                  value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                  message: 'Email is not valid.',
                },
              })}
            />
            {errors.hackIdeaEmail && <div className="errorMsg">{errors.hackIdeaEmail.message}</div>}
            {/* ----------------------------------- */}
            <div className="submit-hack-helper-text-div">
              <label htmlFor="hack-status-input">Status</label>
              <select
                id="hack-idea-status-input"
                className="idea-modal-dropdown"
                onChange={event => handleStatusSelectChange(event)}
                name="hackIdeaStatusDropdown"
                ref={register}
              >
                <option value="Open Idea">Open Idea</option>
                <option value="Looking for Teammates">Looking for Teammates</option>
                <option value="Closed Team">Closed Team</option>
                <option value="Ready to Present">Signed up to Present</option>
              </select>
              <div className="submit-hack-helper-text">{statusText}</div>
            </div>
            {/* ----------------------------------- */}
            {displayLookingFor && (
              <>
                <label htmlFor="hack-looking-for-input">Looking For</label>
                <input
                  className="idea-modal-input-box"
                  type="text"
                  name="hackIdeaLookingFor"
                  id="hack-idea-looking-for-input"
                  placeholder="Designer, Data Engineer...separate by commas"
                  ref={register}
                />
              </>
            )}
            {/* ----------------------------------- */}
            <label htmlFor="hack-product-input">Product Type</label>
            <select
              id="hack-idea-product-input"
              className="idea-modal-dropdown"
              name="hackIdeaProductDropdown"
              ref={register}
            >
              <option value="Data Science">Data Science</option>
              <option value="Inclusion & Impact">Inclusion & Impact</option>
              <option value="Insights">Insights</option>
              <option value="Panel">Panel</option>
              <option value="Promo">Promo</option>
              <option value="Survey">Survey</option>
              <option value="Other/Not Sure">Other/Not Sure</option>
            </select>
            {/* ----------------------------------- */}
            <label htmlFor="hack-type-input">Hack Type</label>
            <select
              id="hack-idea-type-input"
              className="idea-modal-dropdown"
              name="hackIdeaTypeDropdown"
              ref={register}
            >
              <option value="Big Hack">Big Hack</option>
              <option value="Quick Hack">Quick Hack</option>
              <option value="Data Hack">Data Hack</option>
              <option value="Wildcard Hack">Wildcard Hack</option>
            </select>
            {/* ----------------------------------- */}
            <label htmlFor="hack-submission-input">Submission Type</label>
            <select
              id="hack-idea-submission-input"
              className="idea-modal-dropdown"
              name="hackIdeaSubmissionDropdown"
              ref={register}
            >
              <option value="Written Summary">Written Summary</option>
              <option value="Present (Team)">Present to Team</option>
              <option value="Present (North America)">Present Org Wide - Compete</option>
            </select>
            {/* ----------------------------------- */}
            <div className="form-buttons">
              <Button className="close-modal-button" onClick={handleClose}>
                Close
              </Button>
              <Button
                className="form-submit-button"
                id="submit-btn"
                name="create-idea"
                data-testid="submit-idea-modal-button"
                type="submit"
              >
                Submit Hack
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

CreateIdeaModal.propTypes = {
  isCreateIdeaModalVisible: PropTypes.bool.isRequired,
};

export default CreateIdeaModal;

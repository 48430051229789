import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import Card from '../components/hackCard';
import SearchBar from '../components/common/SearchBar';
import { search, compareByHackType } from '../util';
import SkeletonONECard from '../components/SkeletonCardLoader';
import SubmitIdeaBanner from '../components/SubmitIdeaBanner';
import { getHackIdeas } from '../api/CurrentHacks';

const CurrentHackdayPage = () => {
  const [filterhackdayIdeaData, setFilterhackdayIdeaData] = useState([]);
  const [hackdayIdeasData, setHackdayIdeasData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [query, setQuery] = useState('');
  const [searchType, setSearchType] = useState('title');

  useEffect(() => {
    async function setHackdayIdeas() {
      try {
        setIsLoading(true);
        const response = await getHackIdeas();
        const hackIdeaData = response.data;
        hackIdeaData?.sort(compareByHackType);
        setHackdayIdeasData(hackIdeaData);
        setIsLoading(false);
      } catch (error) {
        console.warn(error);
      }
    }
    setHackdayIdeas();
  }, []);

  useEffect(() => {
    if (!query.length) {
      setNoResults(false);
    }
  }, [query.length]);

  const renderNoSearchResults = () => {
    if (noResults && query.length) {
      return <div className="no-results-banner">NO RESULTS</div>;
    }
  };

  const renderCardData = () => {
    const data =
      query.length <= 1 && noResults === false ? hackdayIdeasData : filterhackdayIdeaData;
    return data.map((item, i) => (
      <li key={uuidv4()} style={{ display: 'flex', marginBottom: '15px', width: '525px' }}>
        <Card currentHackday={true} item={item} />
      </li>
    ));
  };

  return (
    <>
      <div
        style={{
          alignItems: 'center',
          margin: '15px 0 ',
        }}
      >
        <SubmitIdeaBanner />
        <SearchBar
          query={query}
          search={event =>
            search(
              event,
              hackdayIdeasData,
              searchType,
              setFilterhackdayIdeaData,
              setNoResults,
              setQuery
            )
          }
          setSearchType={event => setSearchType(event.target.value)}
          hackday="current"
        />
      </div>
      {renderNoSearchResults()}
      {!hackdayIdeasData.length && !isLoading && (
        <div className="no-results-banner">
          <div>No one has added any hacks yet.</div>
          <div>Be the first and submit an idea!</div>
        </div>
      )}
      {isLoading && <SkeletonONECard />}
      {!isLoading && (
        <ul
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
          }}
        >
          {renderCardData()}
        </ul>
      )}
    </>
  );
};

export default CurrentHackdayPage;

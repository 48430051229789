import React, { useState } from 'react';
import CreateIdeaModal from './modals/createIdeaModal';

const SubmitIdeaBanner = () => {
  const [isCreateIdeaModalVisible, setCreateIdeaModal] = useState(false);
  return (
    <div
      style={{
        color: 'white',
        backgroundColor: '#00a8b8',
        borderRadius: '5px',
        margin: 'auto',
        marginBottom: '15px',
        padding: '15px',
        width: '75%',
      }}
    >
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            marginRight: '10px',
          }}
        >
          Have an idea, Looking for teammates, or just looking to submit your Hackday project?
        </div>
        <button
          data-testid="open-modal-button"
          className="submit-idea-btn"
          type="button"
          onClick={() => setCreateIdeaModal(!isCreateIdeaModalVisible)}
        >
          Submit Project
        </button>
        <CreateIdeaModal
          isCreateIdeaModalVisible={isCreateIdeaModalVisible}
          setCreateIdeaModal={setCreateIdeaModal}
        />
      </div>
    </div>
  );
};

export default SubmitIdeaBanner;
